import React, { useEffect } from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logo from '../assets/aflore.png'
import verifiedUser from '../assets/verified_user.svg'
import { useOvermind } from '../overmind';
import { segmentPage } from '../overmind/actions';


const IdentityVerified: React.FC = () => {

    const { state, actions } = useOvermind();
    useEffect(() => {
        segmentPage('validated_identity_viewed', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
    },[])
    return (
        <>
            <Navbar>
                <Navbar.Brand href="#home">
                    <img src={logo} className="img-fluid w-50" />
                </Navbar.Brand>
            </Navbar>

            <Container style={{ marginTop: '1em' }}>


                <header className='text-center'>
                    <h3>¡Hemos validado tu identidad!</h3>
                    <img src={verifiedUser} />
                    <p style={{ marginTop: '1em' }}>
                    Hemos validado tu información ¡estás muy cerca de recibir tu crédito!</p>
                    <p>
                         A continuación, deberás firmar los primeros documentos de tu crédito. Una vez listo, te enviaremos un correo con un pagaré que también deberás firmar.
                        </p>

                        <Button variant="primary" className="shadow h-100 w-100" onClick={actions.reviewContract}>
                        Revisar documentos
                        </Button>
                </header>

            </Container>

        </>
    )
}


export default IdentityVerified
import React, { useEffect } from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logo from '../assets/aflore.png'
import cedulaBack from '../assets/cedula_back.png';
import { useOvermind } from '../overmind';

import ImageUpload from '../components/FileUpload';

import { Applicant } from '../overmind/state'
import { ImageUtil } from '../utils/ImageUtil'

import {
    Formik,
    FormikHelpers,
    FormikProps,
    Form as FormikForm,
    Field,
    FieldProps,
  } from 'formik';
import { segmentPage, segmentTrack } from '../overmind/actions';


interface PersonalDataFrom {
    idType: string;
    documentNumber: string;
    idNumber: string // TODO get rid of dupicate
    idExpeditionDate: string
    lastName: string
    firstName: string
    recentPhoneNumber: string
    cedulaFront: string
    cedulaBack: string
}

const PersonalData: React.FC = () => {

    const { state, actions, effects } = useOvermind();
    useEffect(() => {
        segmentPage('verify_personal_data_viewed', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
        segmentTrack('verify_personal_data_document_type', { "borrower_id": state.loan.borrower_id, "document_type": 'default' } )
    },[])

    const initialValues: PersonalDataFrom = {
        idType: '',
        documentNumber: '',
        idNumber: '', // TODO get rid of dupicate
        idExpeditionDate: '',
        lastName: '',
        firstName: '',
        recentPhoneNumber: '',
        cedulaFront: '',
        cedulaBack: ''
    };

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();


        if (form.checkValidity() === false) {


        } else {
            // TODO figure out why new FormData(form) doesn't work
            let applicant: Applicant = {
                idType: form.idType.value,
                documentNumber: form.idNumber.value,
                idNumber: form.idNumber.value,
                idExpeditionDate: form.idExpeditionDate.value,
                lastName: form.lastName.value,
                recentPhoneNumber: form.recentPhoneNumber.value,
                cedulaFront: '',
                cedulaBack: '',
            }

            // XXX Use native form validation
            if (!('cedula_front_digital_signature' in files_to_upload && 'cedula_back_digital_signature' in files_to_upload)) {
                segmentTrack('verify_personal_data_try_upload_image_error', { "borrower_id": state.loan.borrower_id, 'error':'Por favor, sube una foto de tu cedula' } );
                alert('Por favor, sube una foto de tu cedula')
            } else {
                actions.verifyPersonalData(applicant);
            }

        }
    }

    let files_to_upload: { [key: string]: string; } = {};

    const uploadFileFront = async (file: any, name: string) => {
        segmentTrack('verify_personal_data_try_upload_front_image', { "borrower_id": state.loan.borrower_id } );
        files_to_upload[name] = await ImageUtil.resizeImage(file.file);
        if(Object.keys(files_to_upload).length == 2) {
            effects.api.uploadFiles(files_to_upload, state.loan.borrower_id, state.authToken);
        }
    }

    const uploadFileBack = async (file: any, name: string) => {
        segmentTrack('verify_personal_data_try_upload_back_image', { "borrower_id": state.loan.borrower_id } );
        files_to_upload[name] = await ImageUtil.resizeImage(file.file);
        if(Object.keys(files_to_upload).length == 2) {
            effects.api.uploadFiles(files_to_upload, state.loan.borrower_id, state.authToken);
        }
    }

    const onChangePhone = (e: any) => {
        let value = e.target.value;
        if (!value.match(/^\d{10}$/)) {
            e.target.setCustomValidity("Ingrese un número con diez dígitos");
        } else {
            e.target.setCustomValidity("");
            segmentTrack('verify_personal_data_phone', { "borrower_id": state.loan.borrower_id, "phone": value } )
        }
    }

    const onChangeAccept = (e: any) => {
        let checked = e.target.checked;
        if (!checked) {
            e.target.setCustomValidity("Acepta esta casilla para continuar");
        } else {
            e.target.setCustomValidity("");
            segmentTrack('verify_personal_data_data_treatment', { "borrower_id": state.loan.borrower_id, "data_treatment": checked } )
        }

    }

    const onChangeId = (e: any) => {
        let value = e.target.value;
        segmentTrack('verify_personal_data_document_type', { "borrower_id": state.loan.borrower_id, "document_type": value } )

    }

    const onChangeDate = (e: any) => {
        let value = e.target.value;
        segmentTrack('verify_personal_data_date', { "borrower_id": state.loan.borrower_id, "date": value } )
    }

    const onChangeLastName = (e: any) => {
        let value = e.target.value;
        if(value.length >= 4){
            segmentTrack('verify_personal_data_last_name', { "borrower_id": state.loan.borrower_id, "last_name": value } )
        }

    }

    const onChangeDocument = (e: any) => {
        let value = e.target.value;
        if(value.length >= 4){
            segmentTrack('verify_personal_data_document', { "borrower_id": state.loan.borrower_id, "document": value } )
        }
    }

    // onInvalid={(e) => e.target.setCustomValidity('Acepta esta casilla para continuar')}
    // onInvalid={(e) => e.target.setCustomValidity('Por favor ingrese solo 10 dígitos')}

    return (
        <>
            <Navbar>
                <Navbar.Brand href="#home">
                    <img src={logo} className="img-fluid w-50" />
                </Navbar.Brand>
            </Navbar>

            <Container style={{ marginTop: '1em' }}>
                <header className='text-center'>
                    <h3>Confirma tus datos</h3>

                    <p>Así nos aseguramos que sí eres tú. Llena la información con cuidado.</p>
                </header>

                <div style={{display:'none'}}>

                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        console.log({ values, actions });
                        alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                    }}
                    render={formikBag => (
                        <FormikForm>
                            <Field
                                name="firstName"
                                render={({ field, form, meta }) => (
                                    <div>
                                        <input type="text" {...field} placeholder="First Name" />
                                        {meta.touched && meta.error && meta.error}
                                    </div>
                                )}
                            />
                        </FormikForm>
                    )}
                />
                </div>


                <Form onSubmit={handleSubmit}>

                    <Form.Group>
                        <Form.Label>Tipo de identificación</Form.Label>
                        <Form.Control as="select" id="idType" name="idType" onChange={onChangeId} required>
                            <option value="1">Cédula de ciudadanía</option>
                            <option value="3">Cédula de extranjería </option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>No. de identificación</Form.Label>
                        <Form.Control id="idNumber" name="idNumber" type="text" onInputCapture={onChangeDocument} required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Fecha de expedición</Form.Label>
                        <Form.Control id="idExpeditionDate" name="idExpeditionDate" onInputCapture={onChangeDate} type="date" required />
                    </Form.Group>


                    <Form.Group>
                        <Form.Label>Primer apellido</Form.Label>
                        <Form.Control id="lastName" name="lastName" onInputCapture={onChangeLastName}  type="text" required />
                    </Form.Group>


                    <Form.Group>
                        <Form.Label>Número de celular más reciente</Form.Label>
                        <Form.Control id="recentPhoneNumber" name="recentPhoneNumber"
                            onChange={onChangePhone}
                            type="tel" required />
                    </Form.Group>


                    <label>Fotografía cédula</label>
                    <p>¡Necesitamos una foto de tu cédula por ambos lados! Debes tomarlas directamente con tu celular y subirlas aquí desde tu galería utilizando el botón adjuntar.</p>
                    <div className="row mb-5">
                        <ImageUpload uploadFile={uploadFileFront} name="cedula_front_digital_signature" />
                    </div>

                    <div className="row mb-5">
                        <ImageUpload uploadFile={uploadFileBack} name="cedula_back_digital_signature" />
                    </div>

                    <Form.Check>
                        <Form.Check.Input type='checkbox' isValid required
                            onInvalid={(e) => e.target.setCustomValidity('Acepta esta casilla para continuar')}
                            onChange={onChangeAccept} />
                        <Form.Check.Label style={{ color: 'black' }} id="accept_terms">
                            Acepto el <a href="" onClick={(e) => { e.preventDefault(); actions.toggleTermsModal() }}>tratamiento de datos personales</a>
                        </Form.Check.Label>
                    </Form.Check>

                    <div className="row" style={{ marginTop: '3em', marginBottom: '3em' }}>
                        <div className="col">
                            <Button type="submit" variant="primary" className="shadow" disabled={state.matches({ VERYFING_PERSONAL_DATA: true })}>
                                CONFIRMAR
                            </Button>
                        </div>
                    </div>
                </Form>
            </Container>

        </>
    )
}


export default PersonalData

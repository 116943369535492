import Resizer from 'react-image-file-resizer';

/** Class to provide image processing tools */
class ImageUtil {
    /**
     * Resize an image based on the max height and max width passed as parameters.
     * When the promise is resolved the base64 encode string of the resized image
     * is returned. 
     * @param {File} file image to resize
     * @param {number} maxWidth max width allowed
     * @param {number} maxHeight max height allowed
     * @param {string} format image format
     */
    static async resizeImage(file: File, maxWidth: number = 1280, maxHeight: number = 1024, format: string = 'PNG') {
        return await new Promise<string>(resolve => {
            Resizer.imageFileResizer(
                file,
                maxWidth,
                maxHeight,
                format,
                100,
                0,
                uri => {
                    resolve(uri as string);
                },
                'base64',
                320,
                240,
            );
        });
    }
}

export { ImageUtil }
import React from 'react';
import { Loan } from "../overmind/state"

const LoanInfo: React.FC<{ loan: Loan }> = (params) => {

    const loan = params.loan



    const moneyFormatter = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });

    const f = moneyFormatter.format

    return (
        <dl>
            <dt>Monto aprobado:</dt> <dd>{f(loan.amount)}</dd>
            <dt>Valor cuota mensual:</dt> <dd>{f(loan.monthly_payment)}</dd>
            <dt>Número de cuotas:</dt> <dd>{loan.approved_installment}</dd>
            <dt>Tasa efectiva anual:</dt> <dd>{loan.annual_interest}</dd>

            {loan.insurance ? (
                <>
                <dt>Seguro:</dt> <dd>{loan.insurance}</dd>
                </>) : null}
            <dt>Fecha de pago:</dt> <dd>{loan.payment_day} <span>de cada mes</span></dd>
        </dl>
    )
}

export default LoanInfo
import { OnInitialize } from 'overmind'

const onInitialize: OnInitialize = ({ actions, state, effects }) => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    const token = urlParams.get('token') || process.env.REACT_APP_API_TOKEN;

    state.authToken = token || '';

    analytics.identify()
    analytics.page("disbursement_viewed", "Remote Disbursement")
}

export default onInitialize
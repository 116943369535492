import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Button from 'react-bootstrap/Button';
import { useOvermind } from '../overmind';



function leftPad(num) {
    if(typeof num === 'undefined'){
        return '';
    }
    let numStr = num.toString();
    return numStr.length === 1 ? '0' + numStr : numStr;
}

class CountdownTimer extends React.Component {
    constructor(props) {
        super(props);

       
        this.state = { time: {}, seconds: 120 };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.startTimer()
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            this.props.handleTimerEnd(true);
            clearInterval(this.timer);
        }
    }

    render() {


        return (
            <div class='text-center'>
                <div>
                Tienes:<br/>
                    <strong>{leftPad(this.state.time.m)}:{leftPad(this.state.time.s)}</strong> <br/>

                    minutos antes de que el código expire
                </div>

            </div>
        );
    }
}


export default CountdownTimer
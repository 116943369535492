// TODO maybe remplement as typescript

import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import logo from '../assets/aflore.png'
import { useOvermind } from '../overmind';
import { segmentPage } from '../overmind/actions';


const Exam = (props) => {

  const { state, actions } = useOvermind();
  useEffect(() => {
    segmentPage('exam_viewed', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
  },[])

  let questionData = state.exam['Questions']['Question']

  let questions = questionData.map((q) => {

    let answers = q['Answer'].map((a, i) => {
      let name = 'answers_' + q['@QuestionId']
      let id = name + '_' + i
      return <Form.Check type='radio' name={name} id={id} label={a['#text']} onClick={(e) => actions.setExamAnswer({
        checked: e.target.checked,
        question: q['@Id'],
        answer: a['#text']
      })} />
    })

    return (
      <Form.Group>
        <Form.Label>{q['@Text']}</Form.Label>
        {answers}
      </Form.Group>
    )
  })

  return (

    <>

      <Navbar>
        <Navbar.Brand href="#home">
          <img src={logo} className="img-fluid w-50"/>
        </Navbar.Brand>
      </Navbar>


      <Container style={{ marginTop: '1em' }}>
        <header className='text-center'>
          <h3>preguntas de seguridad</h3>

          <p>Solo tú deberías saber las respuestas de estas preguntas. Es el último paso.</p>
        </header>

        {questions}


        <div className="row" style={{ marginTop: '3em', marginBottom: '3em' }}>
          <div className="col">
            <Button variant="primary" className="shadow" 
            disabled={state.matches({VERYFING_EXAM:true})}
            onClick={(e) => actions.submitExam()}>
            ENVIAR RESPUESTAS
            </Button>
          </div>
        </div>

      </Container>
    </>
  )


}


export default Exam;
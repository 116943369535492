import React, { useEffect } from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import logo from '../assets/aflore.png'
import { useOvermind } from '../overmind';
import LoanInfo from "../components/LoanInfo"
import { segmentPage } from '../overmind/actions';

const CreditConditions: React.FC = () => {

    const { state, actions } = useOvermind();
    useEffect(() => {
        segmentPage('verify_credit_data_viewed', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
    },[])

    const name = state.loan?.firstName;
    const loan = state.loan;

    return (
        <>
            <Navbar>
                <Navbar.Brand href="#home">
                    <img src={logo} className="img-fluid w-50"/>
                </Navbar.Brand>
            </Navbar>

            <Container style={{ marginTop: '1em' }}>
                <header className='text-center'>
                    <h3>¡Revísa las condiciones una última vez!</h3>

                    <p>¡Hola {name} este es el crédito que solicitaste!</p>
                    <p>Revisa que toda la información del crédito sea correcta.</p>
                </header>


                {loan ? <LoanInfo loan={loan} /> : null}

                <p>
                    Si la información es correcta, <strong>firma el contrato.</strong>
                </p>

                <div className="row" style={{ marginTop: '3em', marginBottom: '2em' }}>

                    <div className="col">
                        <Button variant="secondary" className="shadow h-100 w-100" style={{ fontWeight: 'normal' }} 
                        disabled={state.matches({SIGNING_CONTRACT: true})}
                        onClick={(e) => { e.preventDefault(); actions.trackCreditDataIncorrect(); actions.toggleContactModel();}}>
                            La información no es correcta
                        </Button>
                    </div>
                    <br />
                    <div className="col">
                        <Button variant="primary" className="shadow h-100 w-100" onClick={actions.signContract} disabled={state.matches({SIGNING_CONTRACT: true})}>
                            Firmar
                        </Button>
                    </div>
                </div>
            </Container>

        </>
    )
}


export default CreditConditions
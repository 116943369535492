import { string } from "yup"

export type Applicant = {
  documentNumber: string
  idType: string | null,
  idNumber: string | null,
  idExpeditionDate: string | null,
  lastName: string | null,
  recentPhoneNumber: string | null,
  cedulaFront:string | null,
  cedulaBack:string | null
}

export type SegmentEvent = {
  name: string,
  data: {} | null,
}

export type Loan = {
  amount: number
  annual_interest: number,
  approved_installment: number,
  firstName: string,
  insurance: string,
  lastName: string,
  load_id: number,
  payment_day: number,
  monthly_payment: number,
  status: string,
  total_payment: number,
  borrower_id: number
}

export type DataTerms = {
  text: string,
  version: string
}

type State = {
  loan: Loan
  applicant: Applicant
  showContactModal: boolean
  authToken: string
  applicationId: string
  contract: string
  exam: any
  examAnswers: { [key: string]: string; }
  termDocs: { [key: string]: string; }
  pageNumbers: { [key: string]: number; }
  documentsToken: string
  idToken: string
  terms: DataTerms
  cedulaHasError: boolean
  showErrorModal: boolean
  showTermsModal: boolean
  pinHasError: boolean
  pinTimerEnded: boolean
  wrongPinTries: number
  lastErrorMessage: string
}

export const state: State = {
  loan: {
    amount: 0,
    annual_interest: 0,
    approved_installment: 0,
    firstName: "",
    insurance: "",
    lastName: "",
    load_id: 0,
    payment_day: 0,
    status: "",
    total_payment: 0,
    monthly_payment: 0,
    borrower_id: 0,
  },
  terms: {
    "text": "",
    "version": "1.0"
  },
  termDocs: {},
  pageNumbers: {},
  documentsToken: '',
  idToken: '',
  showContactModal: false,
  showErrorModal: false,
  showTermsModal:false,
  cedulaHasError: false,
  pinHasError: false,
  lastErrorMessage: '',
  authToken:'',
  applicationId: "",
  contract: "",
  exam: {},
  examAnswers: {},
  wrongPinTries: 0,
  pinTimerEnded: false,
  applicant: {
    documentNumber: "",
    idType: null,
    idNumber: null,
    idExpeditionDate: null,
    lastName:null,
    recentPhoneNumber:null,
    cedulaFront: null,
    cedulaBack: null
  }
}
import React, { useEffect } from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logo from '../assets/aflore.png'
import face from '../assets/happy_face.svg';
import { useOvermind } from '../overmind';
import { segmentPage } from '../overmind/actions';


const PersonalData: React.FC = () => {

    const { state, actions } = useOvermind();

    const name = state.loan?.firstName;
    useEffect(() => {
        segmentPage('signature_ready_viewed', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
    },[])

    return (
        <>
            <Navbar>
                <Navbar.Brand href="#home">
                <img src={logo} className="img-fluid w-50"/>
                </Navbar.Brand>
            </Navbar>

            <Container style={{ marginTop: '1em' }}>


                <header className='text-center'>
                    <h3>Firma lista.<br />
                    ¡falta un paso!</h3>

                <div style={{margin:'3em 6em'}}>
                    <img src={face} alt=""/>
                </div>


                    <p>{state.loan.firstName} revisa tu correo.</p>
                    <p>Hemos enviado una confirmación del documento que firmaste y en unas horas recibirás el pagaré.</p> 
                    <p>Sigue los siguientes pasos para adquirir tu crédito.</p>
                </header>

                <Form>


                    <div className="row" style={{ marginTop: '3em', marginBottom: '3em' }}>
                        <div className="col">
                            <Button variant="primary" className="shadow w-100" onClick={() => actions.showNextSteps()}>
                                CONTINUAR
                        </Button>
                        </div>
                    </div>
                </Form>
            </Container>

        </>
    )
}


export default PersonalData
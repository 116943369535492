import React from 'react';
import './App.scss';

import { useOvermind } from './overmind';

import StartPage from './containers/StartPage';
import CreditConditions from './containers/CreditConditions'
import CreditConditions2 from './containers/CreditConditions2'
import PersonalData from './containers/PersonalData'
import IdentityVerified from './containers/IdentityVerified'
import PinEntry from './containers/PinEntry'
import Contract from './containers/Contract'
import ContractSigned from './containers/ContractSigned'
import NextSteps from './containers/NextSteps'
import Exam from './containers/Exam'
import { Helmet } from "react-helmet";
import SadFace from './assets/sadface.svg'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'



function App() {

  const { state, actions } = useOvermind()

  return (
    <>

      <Helmet>
        <title>Desembolso Remoto</title>
        <meta name="description" content="" />
      </Helmet>


      {state.matches({ UNVERIFIED: true }) || state.matches({ VERYFING_CEDULA: true }) ? <StartPage /> : null}
      {state.matches({ CEDULA_VERIFIED: true }) ? <CreditConditions /> : null}
      {state.matches({ CREDIT_REQUEST_VERIFIED: true }) || state.matches({ VERYFING_PERSONAL_DATA: true }) ? <PersonalData /> : null}
      {state.matches({ PERSONAL_DATA_VERIFIED: true }) || state.matches({ VERYFING_PIN: true }) || state.matches({ RESENDING_PIN: true }) ? <PinEntry attempt={1} /> : null}
      {state.matches({ RETRY_PIN: true }) || state.matches({ REQUESTING_EXAM: true }) ? <PinEntry attempt={2} /> : null}
      {state.matches({ SHOW_EXAM: true }) || state.matches({ VERYFING_EXAM: true }) ? <Exam /> : null}
      {state.matches({ PIN_VERYFIED: true }) ? <IdentityVerified /> : null}
      {state.matches({ REVIEW_CONTRACT: true }) ? <Contract /> : null}
      {state.matches({ CONTRACT_VERIFIED: true }) || state.matches({ SIGNING_CONTRACT: true }) ? <CreditConditions2 /> : null}
      {state.matches({ CONTRACT_SIGNED: true }) ? <ContractSigned /> : null}
      {state.matches({ NEXT_STEPS: true }) ? <NextSteps /> : null}


      <Modal show={state.showContactModal} onHide={actions.toggleContactModel}>
        <Modal.Header closeButton>
          <Modal.Title>¿Encontraste algún error?</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {state.matches({ CEDULA_VERIFIED: true }) || state.matches({ CONTRACT_VERIFIED: true }) ? (
            <p>¿Estás seguro que la información no corresponde al crédito que solicitaste?</p>
          ) : null}


          <p>Si estás seguro(a), por favor comunícate con nosotros por alguno de los siguientes canales:</p>

          <dl>
            <dt>Teléfono fijo:</dt> <dd>Bogotá 3003227 - Medellín 6042649</dd>
            <dt>Correo electrónico:</dt> <dd>info@aflore.co</dd>
          </dl>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={actions.toggleContactModel}>
            Volver
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal show={state.showErrorModal}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Parece que algo Ha salido mal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">

          <div className="mb-3">
            <img src={SadFace} />
          </div>

          {state.lastErrorMessage ? (
            <p>
              {state.lastErrorMessage}
            </p>
          ) : null}


          <p>
            No hemos podido validar tu información. Vuelve a intentarlo o comunícate con las líneas de atención.
          </p>

          <dl>
            <dt>Teléfono fijo:</dt> <dd>3003227</dd>
            <dt>Correo electrónico:</dt> <dd>info@aflore.co</dd>
          </dl>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => window.location.reload()}>
            Regresar
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal show={state.showTermsModal} onHide={actions.toggleTermsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Tratamiento de datos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.terms.text}
          </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={actions.toggleTermsModal}>
            Volver
          </Button>
        </Modal.Footer>
      </Modal>


    </>

  );
}




export default App;

import React, { useEffect } from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logo from '../assets/aflore.png'
import CountdownTimer from '../components/Timer'
import { useOvermind } from '../overmind';
import { segmentPage } from '../overmind/actions';



const PersonalData: React.FC<{ attempt: number }> = (attempt) => {

    const { state, actions } = useOvermind();
    useEffect(() => {
        segmentPage('pin_viewed', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
    },[])

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        actions.verifyPin(form.pin.value)
    }

    const showExamTrigger = (state.matches({ 'RETRY_PIN': true }) || state.matches({REQUESTING_EXAM: true}) ) && state.pinTimerEnded == true

    return (
        <>
            <Navbar>
                <Navbar.Brand href="#home">
                    <img src={logo} className="img-fluid w-50"/>
                </Navbar.Brand>
            </Navbar>

            <Container style={{ marginTop: '1em' }}>
                <header className='text-center'>
                    <h3>CÓDIGO DE SEGURIDAD</h3>
                </header>

                <Form onSubmit={handleSubmit}>

                    <p>Te enviamos un SMS con un código</p>
                    <p>Escribe el código que recibiste aquí:</p>

                    <Form.Group>
                        <Form.Label>CÓDIGO:</Form.Label>
                        <Form.Control type="text" name="pin" disabled={state.pinTimerEnded} />
                        {state.pinHasError ? (
                            <Form.Text className="text-danger">
                                Codigo incorrecto, vuelve a intentarlo
                            </Form.Text>
                        ) : null}

                    </Form.Group>




                    {showExamTrigger ? (
                        <div className="text-center">
                            <Button variant="secondary" onClick={(e) => {actions.requestExam()}}  disabled={state.matches({REQUESTING_EXAM: true})}>
                                ¿No recibiste el código? Valida tu identidad a través de preguntas
                            </Button>
                        </div>
                    ) : (
                            <>
                                {state.matches({ PERSONAL_DATA_VERIFIED: true }) || state.matches({ RETRY_PIN: true }) ? (
                                    <CountdownTimer resendPin={actions.resendPin} handleTimerEnd={actions.setTimerEnded} />
                                ) : null
                                }

                                {(state.matches({ PERSONAL_DATA_VERIFIED: true }) || state.matches({ RESENDING_PIN: true })) ? (
                                    <div className="text-center mt-3">
                                        <span>¿No has recibido el código?</span><br />
                                        <Button
                                            disabled={state.matches({ RESENDING_PIN: true })}
                                            variant={state.pinTimerEnded ? "primary" : "secondary"}
                                            onClick={(e) => actions.resendPin()}>Reenviar código</Button>
                                    </div>

                                ) : null}
                            </>
                        )}

                    <div className="row" style={{ marginTop: '3em', marginBottom: '3em' }}>
                        <div className="col text-center">
                            <Button variant="primary" className="shadow" type="submit" disabled={state.matches({ VERYFING_PIN: true }) || state.pinTimerEnded}>
                                CONFIRMAR CÓDIGO
                        </Button>
                        </div>
                    </div>
                </Form>
            </Container>

        </>
    )
}


export default PersonalData
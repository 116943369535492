import { IConfig } from 'overmind'
import { createHook } from 'overmind-react'
import { statechart } from 'overmind/config'
import { state } from './state'
import * as actions from './actions'
import * as effects from './effects'
import onInitialize from './onInitialize'

declare module 'overmind' {
  // tslint:disable:interface-name
  interface Config extends IConfig<typeof config> { }
}

const _config = {
  onInitialize,
  state,
  actions,
  effects
}

const creditChart = {
  initial: "UNVERIFIED",
  states: {
    UNVERIFIED: {
      on: {
        setCedula: null,
        verifyCedula: "VERYFING_CEDULA",
      }
    },
    VERYFING_CEDULA: {
      on: {
        cedulaVerified: "CEDULA_VERIFIED",
        cedulaRejected: "UNVERIFIED"
      }

    },
    CEDULA_VERIFIED: {
      on: {
        verifyCreditRequest: "CREDIT_REQUEST_VERIFIED",
        rejectCreditRequest: null,
        toggleContactModel: null
      }
    },
    CREDIT_REQUEST_VERIFIED: {
      on: {
        verifyPersonalData: "VERYFING_PERSONAL_DATA",
        toggleTermsModal: null,
      }
    },
    VERYFING_PERSONAL_DATA: {
      on: {
        personalDataVerified: "PERSONAL_DATA_VERIFIED",
        personalDataRejected: "CREDIT_REQUEST_VERIFIED",
        showExam: "SHOW_EXAM"
      }
    },
    PERSONAL_DATA_VERIFIED: {
      on: {
        verifyPin: "VERYFING_PIN",
        resendPin: "RESENDING_PIN",
        setTimerEnded: null,
      }
    },
    RESENDING_PIN: {
      on: {
        pinResent: "RETRY_PIN",
      }
    },
    RETRY_PIN: {
      on: {
        verifyPin: "VERYFING_PIN",
        setTimerEnded: null,
        requestExam: "REQUESTING_EXAM"
      }
    },
    REQUESTING_EXAM: {
      on: {
        showExam: "SHOW_EXAM",
        requestingExamFailed: null
      }
    },
    SHOW_EXAM: {
      on: {
        setExamAnswer: null,
        submitExam: "VERYFING_EXAM",
      }
    },
    VERYFING_EXAM: {
      on: {
        examVerified: "PIN_VERYFIED",
        examRejected: "SHOW_EXAM"
      }
    },
    VERYFING_PIN: {
      on: {
        pinVeryfied: "PIN_VERYFIED",
        pinRejected: "PERSONAL_DATA_VERIFIED",
      }
    },
    PIN_VERYFIED: {
      on: {
        reviewContract: "REVIEW_CONTRACT",
      }
    },
    REVIEW_CONTRACT: {
      on: {
        verifyContract: "CONTRACT_VERIFIED",
        rejectContract: null,
        toggleContactModel: null,
        setPageNumbers:null,
      }
    },
    CONTRACT_VERIFIED: {
      on: {
        signContract: "SIGNING_CONTRACT",
        toggleContactModel: null
      }
    },
    SIGNING_CONTRACT: {
      on: {
        contractSigned: "CONTRACT_SIGNED",
        rejectContractSignature: null
      }
    },
    CONTRACT_SIGNED: {
      on: {
        showNextSteps: "NEXT_STEPS"
      }
    },
    NEXT_STEPS: {
      on: {
        toggleContactModel: null
      }
    }
  }

};


export const config = statechart(_config, creditChart);


export const useOvermind = createHook<typeof config>()





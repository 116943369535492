import React from 'react';
//import logo from '../assets/logo_comfama_aflore.svg';
import logo from '../assets/aflore.png';
import asideLogo from '../assets/aside.svg'
import corner from '../assets/corner.svg';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useOvermind } from '../overmind';
import { useEffect, useState } from "react";
import { segmentPage } from '../overmind/actions';
const StartPage: React.FC = () => {

  const { state, actions } = useOvermind();

  useEffect(() => {
     return segmentPage('access_credit_viewed')
  }, []);
  //<img src={corner} alt="" style={{ position: 'absolute', top: 0, right: 0 }} />
  //<img src={asideLogo} style={{ position: 'absolute', bottom: '10px', right: '3px' }} />
  return (
    <div className=" h-100 start-page">
      <Container fluid="sm" className="text-center">

        <div style={{ marginTop: '6em', marginBottom: '2em', marginLeft: '2em', marginRight: '2em' }}>
          <img src={logo} className="img-fluid" />
        </div>

        <h2 style={{ marginBottom: '1em' }}>¡Bienvenido!</h2>

        <p>Felicitaciones! Tu crédito ha sido aprobado. Estás a pocos pasos de tenerlo.</p>
        <p>Primero, debemos asegurarnos de que seas tú. Por favor, escribe aquí tu cédula:</p>

        <div style={{ padding: '1.5em' }}>
          <input type="text" className="text-center form-control"
            style={{maxWidth:'400px', margin:'0 auto'}}
            placeholder='Escribe aquí tu cedula'
            value={state.applicant.documentNumber} onChange={(e) => actions.setCedula(e.target.value)} />
          {state.cedulaHasError ? (
            <p className="text-danger">
              Este número de cédula no tiene créditos aprobados asociados. Si necesitas más información comunícate a la línea de atención (1) 3003227
            </p>
          ) : null}
        </div>
        <Button variant="primary" className="shadow-sm" onClick={actions.verifyCedula} disabled={state.matches({ VERYFING_CEDULA: true })}>
          ACCEDER AL CRÉDITO
          </Button>
      </Container>


    </div>
  )
}
export default StartPage;
import React from 'react';
import ImageUploading from "react-images-uploading";

import cedulaFront from '../assets/cedula_front.png';

import cedulaBack from '../assets/cedula_back.png';
import Form from 'react-bootstrap/Form';



function ImageUpload(props: any) {
  const maxNumber = 69;
  const maxMbFileSize = 100;
  const onChange = (imageList: any) => {
    // data for submit
    props.uploadFile(imageList[0], props.name)
  };


  let placeholder = props.name === 'cedula_front_digital_signature' ? cedulaFront : cedulaBack
  return (
    <ImageUploading
      onChange={onChange}
      maxNumber={maxNumber}
      single
      required={true}
      maxFileSize={maxMbFileSize}
      //accept="image/jpeg, image/png, image/jpg"
    >
      {({ imageList, onImageUpload, onImageRemoveAll }) => (
        <>

          
          <div className="col col-8">
            {imageList.map((image) => (
              <img src={image.dataURL} style={{ maxWidth: '250px' }} className="img-fluid" />
            ))}
            {imageList.length == 0 ? <img src={placeholder} style={{ maxWidth: '250px' }} /> : null}
          </div>
          <div className="col col-4">
            <button type="button" className="btn bg-primary h-100 text-center align-middle text-white rounded" onClick={onImageUpload}>
              Adjuntar fotografía diferente</button>
          </div>

        </>
      )}
    </ImageUploading>
  );
}


const xImageUpload = (props: any) => {

  const onChange = (imageList: any) => {
    // data for submit
    props.uploadFile(imageList[0], props.name)
  };

  let imageList;
  let onImageUpload;
  let onImageRemoveAll;

  let placeholder = props.name === 'id_verification_front' ? cedulaFront : cedulaBack
  console.log('image component', props)
  return (
    <>


      <ImageUploading
        onChange={onChange}
        maxNumber={1}
        multiple
        maxFileSize={100}
        acceptType={["jpg", "gif", "png"]}
      >
        {({ imageList, onImageUpload, onImageRemoveAll }) => (
          // write your building UI
          <div>
            <button onClick={onImageUpload}>Upload images</button>
            <button onClick={onImageRemoveAll}>Remove all images</button>

            {imageList.map((image) => (
              <div key={image.key}>
                <img src={image.dataURL} />
                <button onClick={image.onUpdate}>Update</button>
                <button onClick={image.onRemove}>Remove</button>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>

      <ImageUploading
        onChange={onChange}
        single
        acceptType={["jpg", "gif", "png"]}
      >
        {({ imageList, onImageUpload, onImageRemoveAll }) => (
          // write your building UI
          <>
            inner
                    <div className="col">
              {imageList.map((image) => (
                <img src={image.dataURL} style={{ maxWidth: '250px' }} />
              ))}
              {imageList.length == 0 ? <img src={placeholder} style={{ maxWidth: '250px' }} /> : null}
            </div>
            <div className="col">
              <div className="bg-primary h-100 text-center align-middle text-white rounded" style={{ paddingTop: '35%' }} onClick={onImageUpload}>
                Adjuntar fotografía diferente</div>
            </div>

          </>
        )}
      </ImageUploading>
    </>
  )
}


export default ImageUpload
import React, { useEffect } from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import logo from '../assets/aflore.png';
import LoanInfo from "../components/LoanInfo"


import { useOvermind } from '../overmind';
import { segmentPage } from '../overmind/actions';


const CreditConditions: React.FC = () => {

    const { state, actions } = useOvermind();

    const name = state.loan?.firstName;
    const loan = state.loan;
    useEffect(() => {
        segmentPage('summary_viewed', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
    },[])

    return (
        <>
            <Navbar>
                <Navbar.Brand href="#home">
                    <img src={logo} className="img-fluid w-50"/>
                </Navbar.Brand>
            </Navbar>

            <Container style={{ marginTop: '1em' }} >
                <header className='text-center' >
                    <h3>Condiciones del crÉdito</h3>

                    <p>¡Hola <strong>{name}</strong>! Esta es la información del crédito que solicitaste.</p>
                    <p> Revísala cuidadosamente</p>
                </header>

                {loan ? <LoanInfo loan={loan} />: null}



                <div className="row" style={{ marginTop: '3em' }}>
                    <div className="col">
                        <Button variant="secondary" className="shadow h-100" style={{ fontWeight: 'normal' }} onClick={(e) => {  actions.trackIncorrect(); e.preventDefault(); actions.toggleContactModel() }}>
                            La información no es correcta
                        </Button>
                    </div>
                    <br />
                    <div className="col">
                        <Button variant="primary" className="shadow h-100 w-100" onClick={actions.verifyCreditRequest}>
                            ¡Todo esta bien!
                        </Button>
                    </div>
                </div>
            </Container>

        </>
    )
}


export default CreditConditions
import React, { useEffect } from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logo from '../assets/aflore.png'
import { useOvermind } from '../overmind';
import { Document, Page, pdfjs } from 'react-pdf';
import AutoSizer from 'react-virtualized-auto-sizer';
import { segmentPage } from '../overmind/actions';



// https://github.com/wojtekmaj/react-pdf/issues/321#issuecomment-451291757
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const PersonalData: React.FC = () => {

    const { state, actions } = useOvermind();
    useEffect(() => {
        segmentPage('contract_viewed', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
    },[])
    function onDocumentLoaded(pdf, docName) {
        actions.setPageNumbers([docName, pdf.numPages])
    }

    let width = window.innerWidth;
    let baseUrl = process.env.REACT_APP_API_BASE_URL + 'digital_signature_documents/';

    let docs = Object.keys(state.termDocs).map((docName) => {
        return (
            <Container style={{ marginTop: '1em' }}>
                <AutoSizer disableHeight>
                    {({ width }) => (
                        <Document file={{ url: baseUrl + '?' + (new URLSearchParams({document_name:docName, token: state.documentsToken})).toString()}}
                         onLoadSuccess={(pdf) => onDocumentLoaded(pdf, docName)}loading="Cargando document...">
                            {Array.from(
                                new Array(state.pageNumbers[docName]),
                                (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        width={width} 
                                    />
                                ),
                            )}
                        </Document>

                    )}
                </AutoSizer>

                <div style={{marginTop: '1em'}}>
                    <a href={baseUrl + '?' + (new URLSearchParams({document_name:docName, token: state.documentsToken})).toString()} onClick={actions.trackOpenDocument} target="_blank">
                    Abrir documento en nueva pestaña
                    </a>
                </div>

            </Container>
        )
    })

    return (
        <>
            <Navbar>
                <Navbar.Brand href="#home">
                    <img src={logo} className="img-fluid w-50" />
                </Navbar.Brand>
            </Navbar>
            <article>

            <Container style={{ marginTop: '1em' }}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: state.contract
                    }}></div>

            </Container>

                {docs}


            </article>

            <Container style={{ marginTop: '1em' }}>

                <Form>



                    <div className="row" style={{ marginTop: '3em', marginBottom: '3em' }}>
                        <div className="col">
                            <Button variant="secondary" className="shadow h-100" style={{ fontWeight: 'normal' }} onClick={(e) => {actions.trackContractIncorrect(); actions.toggleContactModel()}}>
                                La información no es correcta
                        </Button>
                        </div>
                        <div className="col">
                            <Button variant="primary" className="shadow h-100 w-100" onClick={() => actions.verifyContract()}>
                                Firmar
                        </Button>
                        </div>
                    </div>
                </Form>
            </Container>

        </>
    )
}


export default PersonalData
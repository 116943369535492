import React, { useEffect } from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logo from '../assets/aflore.png'
import emailImg from '../assets/email-24px.svg'
import assignmentImg from '../assets/assignment.svg'
import watchImg from '../assets/watch_later-24px.svg'
import checkImg from '../assets/check_circle-24px.svg'
import { useOvermind } from '../overmind';
import { segmentPage } from '../overmind/actions';


const NextSteps: React.FC = () => {

    const { state, actions } = useOvermind();
    useEffect(() => {
        segmentPage('next_steps_viewed', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
    },[])
    return (
        <>
            <Navbar>
                <Navbar.Brand href="#home">
                    <img src={logo} className="img-fluid w-50" />
                </Navbar.Brand>
            </Navbar>

            <Container style={{ marginTop: '1em' }}>


                <header className='text-center'>
                    <h3>¿Qué sigue ahora?</h3>
                    <p>Para recibir tu dinero:</p>
                </header>

                <ol className="list-unstyled">
                    <li className="shadow p-3 mb-3 bg-white rounded">

                        <div className="media text-left">
                            <div className="media-body">
                                <h5 className="mt-0 text-left color-primary">Tu plata está en Bancolombia</h5>
                                <p>Tienes 24 horas para retirarla por ventanilla después de firmar el pagaré. Recuerda llevar tu cédula original.</p>
                            </div>
                            <img className="align-self-center mr-3" src={watchImg} alt="" />
                        </div>

                    </li>
                    <li className="shadow p-3 mb-3 bg-white rounded">

                        <div className="media text-left">
                            <div className="media-body">
                                <h5 className="mt-0 text-left color-primary">¡Usa bien el dinero!</h5>
                                <p>Nos enorgullece poder ayudarte en este momento. Esperamos que aproveches este préstamo</p>
                            </div>
                            <img className="align-self-center mr-3" src={checkImg} alt="" />
                        </div>
                    </li>
                </ol>

                <div style={{ margin: '2em 0' }} className="text-center">
                <p>
                    ¿Necesitas ayuda? <br /> contáctanos:
                    </p>

                <Button variant='secondary' onClick={(e) => actions.toggleContactModel()}>
                    LÍNEAS DE ATENCIÓN
                    </Button>

                </div>

            </Container>

        </>
    )
}


export default NextSteps

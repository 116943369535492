import { action, Action, AsyncAction } from 'overmind';
import { Loan, SegmentEvent } from './state'
import { Applicant } from './state'

export const setPageNumbers: Action<any> = ({ state }, props) => {
  state.pageNumbers[props[0]] = props[1]
}

export const setTimerEnded: Action<boolean> = ({ state }, timerStatus) => {
  state.pinTimerEnded = timerStatus
}

export const toggleContactModel: Action = ({ state }) => {
  state.showContactModal = !state.showContactModal
}

export const toggleErrorModal: Action = ({ state }) => {
  state.showErrorModal = !state.showErrorModal
}
export const toggleTermsModal: Action = ({ state }) => {
  state.showTermsModal = !state.showTermsModal
}

export const setCedula: Action<string> = ({ state }, documentNumber) => {
  state.applicant.documentNumber = documentNumber;
}

export const verifyCedula: AsyncAction = async ({ effects, state, actions }) => {
  try {
    const terms = await effects.api.fetchDataTerms(state.authToken);
    state.terms = terms;
    const loan = await effects.api.fetchLoanFor(state.applicant, state.authToken);
    segmentTrack('access_credit_send', { "document_number": state.applicant.documentNumber })
    actions.cedulaVerified(loan)
  } catch (error) {
    actions.cedulaRejected(error)
  }
}

export const cedulaVerified: Action<Loan> = ({ state }, loan) => {
  state.cedulaHasError = false
  state.loan = loan
  segmentIdentify(loan.borrower_id)
  segmentTrack('access_credit_send_ok', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
 }


export const cedulaRejected: Action<any> = ({ state, actions }, error) => {
  // TODO don't use any
  segmentTrack('access_credit_send_error', { "document_number": state.applicant.documentNumber, error: (error.response && error.response.data) ? error.response.data : 'no data'})
  state.cedulaHasError = true;
  state.applicant.documentNumber = ''
}

export const verifyCreditRequest: Action = ({ state }) => {
  segmentTrack('summary_correct', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
}

export const rejectCreditRequest: Action = ({ state }) => {
}

export const verifyPersonalData: AsyncAction<Applicant> = async ({ state, actions, effects }, applicant) => {
  segmentTrack('verify_personal_data_send', { "borrower_id": state.loan.borrower_id, data: applicant } );
  state.applicant = applicant
  try {
    await effects.api.confirmDataTerms(state.loan.borrower_id, state.terms.version, state.authToken)
    let result = await effects.api.verifyPersonalData(state.applicant, state.authToken)
    if (result.ApplicationId) {
      state.idToken = result.id_token
      actions.personalDataVerified(result);
    } else if (result.status == 'exam') {
      segmentTrack('verify_personal_data_send_exam', { "borrower_id": state.loan.borrower_id } );
      state.idToken = result.id_token
      actions.showExam(result);
    }
  } catch (error) {
    actions.personalDataRejected(error)
  }
}

export const showExam: Action<any> = ({ state }, result) => {
  state.exam = result.data.exam.ExamDetails
  state.applicationId = result.data.ApplicationId
}

export const setExamAnswer: Action<any> = ({ state }, props) => {
  segmentTrack('exam_reply', { "borrower_id": state.loan.borrower_id } );
  state.examAnswers[props.question] = props.answer

}

export const submitExam: AsyncAction = async ({ state, effects, actions }) => {
  segmentTrack('exam_send', { "borrower_id": state.loan.borrower_id } );
  try {
    let result = await effects.api.submitExam(state.applicationId,
      state.exam,
      state.examAnswers,
      state.idToken,
      state.authToken)

    state.documentsToken = result.auth
    state.termDocs = result.result.documents
    actions.examVerified(result)

  } catch (error) {
    actions.examRejected(error)
  }
}

export const examVerified: AsyncAction<any> = async ({ state, effects }) => {
  segmentTrack('exam_send_ok', { "borrower_id": state.loan.borrower_id } );
  let contract = await effects.api.fetchContract(state.applicant.documentNumber, state.loan.load_id, state.loan.borrower_id, state.authToken)
  state.contract = contract
}

export const examRejected: Action<any> = ({ state }, error) => {
  segmentTrack('exam_send_error', { "borrower_id": state.loan.borrower_id, error: error.response.data.message } );
  state.lastErrorMessage = error.response.data.message;
  state.showErrorModal = true
}

export const personalDataVerified: Action<any> = ({ state }, idvResult) => {
  state.applicationId = idvResult.ApplicationId;
  if (idvResult.OTPCode) {
    alert("Test code: " + idvResult.OTPCode)
  }

}

export const personalDataRejected: AsyncAction<any> = async ({ state, actions }, error) => {
  console.log(error,'error')
  segmentTrack('verify_personal_data_send_error', { "borrower_id": state.loan.borrower_id, error:  error.response.data.message } );
  state.lastErrorMessage = error.response.data.message;
  state.showErrorModal = true
}

export const verifyPin: AsyncAction<string> = async ({ state, actions, effects }, pin) => {
  try {
    segmentTrack('pin_send', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
    let data = await effects.api.verifyPin(pin, state.applicationId, state.loan.borrower_id, state.idToken, state.authToken);
    state.documentsToken = data.auth
    state.termDocs = data.data.documents
    actions.pinVeryfied();
  } catch (error) {
    actions.pinRejected(error)
  }
}


export const resendPin: AsyncAction = async ({ state, actions, effects }) => {
  try {
    segmentTrack('pin_resend', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
    let result = await effects.api.resendPin(state.applicationId, state.authToken)
    if (result.OTPCode) {
      alert("Test code: " + result.OTPCode)
    }
    actions.pinResent()
  } catch (error) {
    segmentTrack('pin_resend_error', { "borrower_id": state.loan.borrower_id, "error": error.response.data.message } )
    state.lastErrorMessage = error.response.data.message;
    state.showErrorModal = true
  }
}

export const requestExam: AsyncAction = async ({ state, effects, actions }) => {
  try {
    let result = await effects.api.requestExam(state.applicationId, state.authToken)
    actions.showExam(result);
  } catch (error) {
    actions.requestingExamFailed(error)
  }
}

export const requestingExamFailed: Action<any> = ({ state }, error) => {
  state.lastErrorMessage = error.response.data.message;
  state.showErrorModal = true
}

export const pinVeryfied: AsyncAction = async ({ state, effects }) => {
  segmentTrack('pin_send_ok', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
  state.pinHasError = false
  let contract = await effects.api.fetchContract(state.applicant.documentNumber, state.loan.load_id, state.loan.borrower_id, state.authToken)
  state.contract = contract
}

export const pinRejected: Action<any> = ({ state }, error) => {
  state.lastErrorMessage = error.response.data.message;
  state.wrongPinTries += 1
  state.pinHasError = true
  if (state.wrongPinTries > 1) {
    segmentTrack('pin_send_error', { "borrower_id": state.loan.borrower_id, "error": state.lastErrorMessage } )
    state.showErrorModal = true
  }
}

export const pinResent: Action = ({ state }) => {
  state.pinTimerEnded = false;
}

export const reviewContract: Action = ({ state }) => {

}

export const verifyContract: Action = ({ state }) => {
  segmentTrack('contract_correct', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
}

export const rejectContract: Action = ({ state }) => { }

export const signContract: AsyncAction = async ({ state, actions, effects }) => {
  try {
    segmentTrack('verify_credit_data_send', { "borrower_id": state.loan.borrower_id, "error": state.lastErrorMessage } )
    let contract = await effects.api.signContract(
      state.applicationId,
      state.applicant.documentNumber,
      state.loan.load_id,
      state.loan.borrower_id,
      state.documentsToken,
      state.authToken)

    actions.contractSigned()
  } catch (error) {
    actions.rejectContractSignature(error)
  }

}

export const contractSigned: Action = ({ state }) => {
  segmentTrack('verify_credit_data_send_ok', { "borrower_id": state.loan.borrower_id, "error": state.lastErrorMessage } )
}

export const rejectContractSignature: Action<any> = ({ state }, error) => {
  segmentTrack('verify_credit_data_error', { "borrower_id": state.loan.borrower_id, "error": state.lastErrorMessage } )
  state.lastErrorMessage = error.response.data.message;
  state.showErrorModal = true
}

export const showNextSteps: Action = ({ state }) => {
  segmentTrack('signature_ready_send', { "borrower_id": state.loan.borrower_id, "error": state.lastErrorMessage } )
}


export const segmentIdentify = (id:any) => {
  console.log('Id ' + id)
  analytics.identify(id)
}
export const  segmentPage = (title:string, data:{}={}) => {
  if(Object.keys(data).length === 0){
    console.log('Page ' + title)
    analytics.page(title)
  }else{
    console.log('Page ' + title)
    analytics.page(title, data)
  }

}
export const segmentTrack = (title:string, data:{}={}) => {
  if(Object.keys(data).length === 0){
    console.log('Track ' + title)
    analytics.track(title)
  }else{
    console.log('Track ' + title)
    analytics.track(title, data)
  }
}
export const trackIncorrect: Action = ({ state  }) => {
  segmentTrack('summary_incorrect', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
}

export const trackOpenDocument: Action = ({ state  }) => {
  segmentTrack('contract_open_document', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
}

export const trackContractIncorrect: Action = ({ state  }) => {
  segmentTrack('contract_incorrect', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
}

export const trackCreditDataIncorrect: Action = ({ state  }) => {
  segmentTrack('verify_credit_data_incorrect', { "borrower_id": state.loan.borrower_id, "document_number": state.applicant.documentNumber } )
}


import axios from 'axios';
import { Applicant, Loan, state } from './state'
import { stringify } from 'querystring';

let apiUrl = process.env.REACT_APP_API_BASE_URL;

// TODO Figure out how to setup axios once with the Authentication headers obtained in runtime


const promise1 = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        'OTPCode': 123
      });
    }, 1000);
  });

  

export const api = {

    // TODO confiure return type Promise<Loan>
    fetchLoanFor(applicant: Applicant, token: string) {
        let url = apiUrl + 'digital_signature/'
        return axios.get(url, {
            params: {
                document_number: applicant.documentNumber,
                option: 0
            },
            headers: {
                'Authorization': token
            }
        }).then(response => {
            if (!response.data.result) {
                return Promise.reject();
            }
            let data = response.data.data.borrower_approved;
            let url = apiUrl + 'digital_signature/'
            return axios.get(url, {
                params: {
                    document_number: applicant.documentNumber,
                    borrower_id: data.borrower_id,
                    option: 1
                },
                headers: {
                    'Authorization': token
                }
            }).then(resp => {
                let result = resp.data.data;
                // XXX: return loan and borrower seperatly
                result['borrower_id'] = data.borrower_id
                return result;
            })
        })
    },

    fetchDataTerms( token:string){
        let url = apiUrl + 'digital_signature_data_treatment/'
        return axios.get(url, {
            headers: {
                'Authorization': token
            }

        }).then(response => response.data.data);
    },

    confirmDataTerms(borrower_id: number, version:string, token:string){
        let url = apiUrl + 'digital_signature_data_treatment/'
        return axios.post(url, {
            "borrower_id": borrower_id,
            "version": version

        }, {
            headers: {
                'Authorization': token
            }

        }).then(response => response.data.data);
    },

    verifyPersonalData(applicant: Applicant, token: string) {
        let url = apiUrl + 'digital_signature_process/'
        return axios.post(url, applicant, {
            params: {
                'transaction': "init_idv"
            },
            headers: {
                'Authorization': token
            }

        }).then(response => response.data.data);
    },


    resendPin(applicantId: string, token: string) {
        let url = apiUrl + 'digital_signature_process/'
        return axios.post(url, { 'ApplicationId': applicantId }, {
            params: {
                'transaction': "resend_pin"
            },
            headers: {
                'Authorization': token
            }

        }).then(response => response.data.data);
    },

    requestExam(applicantId: string, token:string){
        let url = apiUrl + 'digital_signature_process/'
        return axios.post(url, { 'ApplicationId': applicantId }, {
            params: {
                'transaction': "request_exam"
            },
            headers: {
                'Authorization': token
            }

        }).then(response => response.data.data);

    },


    submitExam(applicationId: string, exam: any, examAnswers: { [key: string]: string; }, idToken:string, token: string) {
        let url = apiUrl + 'digital_signature_process/'
        return axios.post(url, { "exam": exam, "examAnswers": examAnswers, 'ApplicationId': applicationId, "idToken":idToken }, {
            params: {
                'transaction': "submit_exam"
            },
            headers: {
                'Authorization': token
            }

        }).then(response => response.data.data);
    },

    verifyPin(pin: string, applicationId: string, borrowerId:number, idToken:string, token: string) {
        let url = apiUrl + 'digital_signature_process/'
        return axios.post(url, { "pin": pin, 'ApplicationId': applicationId, "borrower_id": borrowerId, "idToken":idToken }, {
            params: {
                'transaction': "verify_pin"
            },
            headers: {
                'Authorization': token
            }

        }).then(response => response.data.data);
    },

    fetchContract(documentNumber: string, loanId: number, borrowerId: number, token: string) {
        let url = apiUrl + 'digital_signature/documents/'

        return axios.get(url, {
            params: {
                document_number: documentNumber,
                borrower_id: borrowerId,
                loan_id: loanId,
                option: 0
            },
            headers: {
                'Authorization': token
            }
        }).then(response => response.data);

    },

    fetchAdditionalDocuments(documentNumber: string, loanId: number, borrowerId: number, token: string) {
        let url = apiUrl + 'digital_signature/documents/'

        return axios.get(url, {
            params: {
                document_number: documentNumber,
                borrower_id: borrowerId,
                loan_id: loanId,
                option: 1
            },
            headers: {
                'Authorization': token
            }
        }).then(response => response.data);

    },

    signContract(applicationId: string, documentNumber: string, loanId: number, borrowerId: number, documentsToken: string, token: string) {

        let url = apiUrl + 'digital_signature_process/'
        return axios.post(url, {
            "documentNumber": documentNumber,
            "loanId": loanId,
            "borrowerId": borrowerId,
            'ApplicationId': applicationId,
            'token': documentsToken
        }, {
            params: {
                'transaction': "sign_document"
            },
            headers: {
                'Authorization': token
            }

        }).then(response => response.data.data);

    },

    uploadFiles(files: { [key: string]: string; }, borrowerId: number, token: string) {
        let url = apiUrl + 'upload_file_borrower/';
        axios.post(url,
            files,
            {
                params: {
                    borrower_id: borrowerId,
                    option: 1
                },
                headers: {
                    'Authorization': token
                }
            }
        )
    }
}
